<template>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3  text-white">Polityka prywatności</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section class="section section-lg pt-lg-0 mt--200">
                <div class="container">
                    <div class="row justify-content-center">
                        <card class="border-0" shadow body-classes="py-5">
                            <h4 class="text-duo text-uppercase">Ochrona prywatności</h4>

                            <h6 class="text-duo text-uppercase mt-5">Administrator danych osobowych</h6>
                            <p class="description mt-2">Kancelaria DUO LEGAL jest administratorem danych osobowych klientów, osób kontaktowych klientów oraz osób, 
                                których dane są przekazane przez klientów, jeśli jest to niezbędne do wykonania usługi. 
                                W zakresie, w jakim kancelaria przetwarza takie dane osobowe w celu świadczenia pomocy prawnej, staje się z mocy prawa ich administratorem. 
                                Kancelaria przetwarza również dane osobowe osób, które uczestniczą w organizowanych przez nas wydarzeniach, aplikują o pracę, pracują lub świadczą dla nas usługi.</p>

                            <h6 class="text-duo text-uppercase mt-4">Cele i podstawy przetwarzania danych</h6>
                            <p class="description mt-2">Przetwarzamy dane w następujących celu realizacji przez Kancelarię DUO LEGAL jej uzasadnionych prawnie interesów, 
                                w szczególności w postaci zapewnienia bezpieczeństwa, wykonania usługi prawnej, działań marketingowych, rekrutacji i zatrudnienia, 
                                realizacji obowiązków wynikających z przepisów prawa. Kancelaria nie podejmuje decyzji wobec osób, których dane dotyczą, w oparciu o zautomatyzowane przetwarzanie, w tym profilowanie.</p>

                            <h6 class="text-duo text-uppercase mt-4">Zakres przetwarzanych danych osobowych</h6>
                            <p class="description mt-2">Zakresem przetwarzanych przez Kancelarię DUO LEGAL danych osobowych objęte są imię i nazwisko, adres, numer telefonu, adres e-mail klienta.</p>
                            <p class="description">Osobom, których dotyczą przetwarzane dane, przysługują następujące prawa:
                            <ul>
                                <li>
                                    prawo do informacji, które realizuje między innymi niniejsza Nota prawna i polityka prywatności,
                                </li>
                                <li>
                                    prawo dostępu do danych,
                                </li>
                                <li>
                                    prawo do sprostowania danych,
                                </li>
                                <li>
                                    prawo do usunięcia danych,
                                </li>
                                <li>
                                    prawo do ograniczenia przetwarzania danych,
                                </li>
                                <li>
                                    prawo do sprzeciwu,
                                </li>
                            </ul>
                            </p>

                            <h6 class="text-duo text-uppercase mt-4">Ograniczenie udostępniania informacji</h6>
                            <p class="description mt-2">Kancelaria DUO LEGAL przechowuje dane przez okres korzystania przez klienta z usług Kancelarii lub przez okres niezbędny dla zrealizowania celów, dla których dane te zostały zebrane.</p>
                            <p class="description">W sprawach nieuregulowanych powyżej zastosowanie mają postanowienia Kodeksu cywilnego i odpowiednich ustaw prawa polskiego, 
                                a także prawa Unii Europejskiej, w szczególności RODO (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. 
                                w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE).</p>
                        </card>
                    </div>
                </div>
            </section>
        
    </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
export default {
  components: {
    Tabs,
    TabPane
  }
};
</script>
<style>
</style>
