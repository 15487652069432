<template>
    <div>
        <hero></hero>
        <short-about></short-about>
        <our-location></our-location>
    </div>
</template>
<script>
import Hero from "./components/Hero";
import BasicElements from "./components/BasicElements";
import Inputs from "./components/Inputs";
import CustomControls from "./components/CustomControls";
import Navigation from "./components/Navigation";
import JavascriptComponents from "./components/JavascriptComponents";
import Carousel from "./components/Carousel";
import Icons from "./components/Icons";
import Examples from "./components/Examples";
import DownloadSection from "./components/DownloadSection";
import ShortAbout from "./components/ShortAbout";
import ShortTeam from "./components/ShortTeam";
import OurLocation from "./components/OurLocation";

export default {
  name: "components",
  components: {
    Hero,
    BasicElements,
    Inputs,
    CustomControls,
    Navigation,
    JavascriptComponents,
    Carousel,
    Icons,
    Examples,
    DownloadSection,
    ShortAbout,
    ShortTeam,
    OurLocation
  }
};
</script>
<style>

</style>