<template>
  <div class="container">
    <div class="card">
      <h5 class="card-header">
        Nasza lokalizacja
      </h5>
      <div class="card-body">

        <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true"
                data-projection="EPSG:4326" style="height: 600px">
          <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

          <vl-overlay id="overlay" :position="overlayCoordinate" :offset="overlayOffset">
            <template>
              <div class="card shadow">
                  <div class="card-body">
                    <span class="lead duolegal_color">Duo legal</span> <br/>
                    ul. Elektryczna 2 lok. 57 <br/>
                    00-346 Warszawa
                  </div>
              </div>
            </template>
          </vl-overlay>
          
          <vl-feature>
            <vl-geom-point :coordinates="[21.029460, 52.239580]"></vl-geom-point>
          </vl-feature>

          <vl-layer-vector>
            <vl-feature>
              <vl-geom-point
                :coordinates="[21.029460, 52.239580]"
              ></vl-geom-point>

              <vl-style-box>
                <vl-style-circle :radius="10">
                  <vl-style-fill color="#c23b8e"></vl-style-fill>
                  <vl-style-stroke color="black"></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </vl-layer-vector>
          <vl-layer-tile id="osm">
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>
      </vl-map>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return { 
        zoom: 16,
        controls: true,
        center: [21.029460, 52.239580],
        rotation: 0,
        geolocPosition: undefined,
        overlayCoordinate: [21.029460, 52.239580],
        overlayOffset: [10, 10]
      }
    },
  }
</script>
<style>
.overlay-content{
  background-color: white;
}
</style>