<template>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-8">
                            <blockquote class="blockquote text-white">
                                <p class="h5 mb-0 text-white">&bdquo;Spotkać się, to początek, zgodzić się to postęp, pracować razem, to sukces.&ldquo;</p>
                                <footer class="blockquote-footer">Henry Ford</footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 1st Hero Variation -->
        
        <section class="section section-lg pt-lg-0 mt--200">

            <div class="container">
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <div class="row row-grid align-items-center">
                            <div class="pl-md-5 pr-md-5">
                                <div class="text-center mt-5">
                                    <!-- <h4 class="duolegal_color text-center">Duo legal
                                    </h4> -->
                                    <!-- <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Bucharest, Romania</div> -->
                                    <div class="mt-4 justify-content-center">
                                        <span class="lead duolegal_color">Duo legal</span>
                                        to zespół profesjonalistów, prawników, specjalizujących się w szczególności w prawie gospodarczym i sprawach spornych.
                                        Posiadamy wiedzę i doświadczenie z zakresu sporów instytucji finansowych z konsumentami,
                                        w szczególności w tzw. „sporach frankowych”, w świadczeniu usług prawnych w zakresie kompleksowej obsługi korporacyjnej małych,
                                        średnich i dużych przedsiębiorców oraz świadczeniu profesjonalnych usług z zakresu prawa karnego,
                                        karnego gospodarczego oraz prawa wykroczeń. Ponadto, nasz zespół oferuje wszechstronne wsparcie w obszarach prawa istotnych dla biznesu.</div>
                                    <!-- <div><i class="ni education_hat mr-2"></i>University of Computer Science</div> -->
                                </div>
                                <div class="mt-5 py-5 border-top text-center">
                                    <div class="row justify-content-center">
                                        <div>
                                            <p>Świadcząc usługi prawne pomagamy sprostać wyzwaniom, którym stawiają czoła nasi klienci i wspieramy ich podejmując efektywne działania.
                                                Korzystamy z nowoczesnych rozwiązań, szczególną uwagę przykładamy do dyspozycyjności i responsywności naszych prawników w relacjach z klientami.</p>
                                            <!-- <p class="text-right">ahajdasz@duolegal.pl <br/>525 123 222 </p> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <base-button type="default" tag="a" href="mailto:ahajdasz@duolegal.pl" size="sm" class="float-right">Skontaktuj się</base-button> -->
                            </div>
                            </div>

                        <!-- <tab-pane title="Aleksandra Hajdasz">
                            
                            <div class="row row-grid align-items-center">
                                <div class="col-md-4">
                                    <img v-lazy="'img/theme/ola_tall.jpg'" class="img-fluid rounded shadow-lg">
                                </div>
                                <div class="col-md-8">
                                    <div class="pl-md-5">
                                        <div class="text-center mt-5">
                                            <h3>Aleksandra Hajdasz
                                                <span class="font-weight-light">, 27</span>
                                            </h3>
                                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Bucharest, Romania</div>
                                            <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer</div>
                                            <div><i class="ni education_hat mr-2"></i>University of Computer Science</div>
                                        </div>
                                        <div class="mt-5 py-5 border-top text-center">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-9">
                                                    <p>An artist of considerable range, Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range.</p>
                                                    <p class="text-right">ahajdasz@duolegal.pl <br/>525 123 222 </p>
                                                </div>
                                            </div>
                                        </div>
                                        <base-button type="default" tag="a" href="mailto:ahajdasz@duolegal.pl" size="sm" class="float-right">Skontaktuj się</base-button>
                                    </div>
                                </div>
                            </div>
                        </tab-pane>

                        <tab-pane title="Adrianna Grzybowska">
                            <div class="row row-grid align-items-center">
                                <div class="col-md-4">
                                    <img v-lazy="'img/theme/adrianna_tall.jpg'" class="img-fluid rounded shadow-lg">
                                </div>
                                <div class="col-md-8">
                                    <div class="pl-md-5">
                                        <div class="text-center mt-5">
                                            <h3>Adrianna Grzybowska
                                                <span class="font-weight-light">, 27</span>
                                            </h3>
                                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Bucharest, Romania</div>
                                            <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer</div>
                                            <div><i class="ni education_hat mr-2"></i>University of Computer Science</div>
                                        </div>
                                        <div class="mt-5 py-5 border-top text-center">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-9">
                                                    <p>An artist of considerable range, Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range.</p>
                                                    <p class="text-right">agrzybowska@duolegal.pl <br/>525 321 333 </p>
                                                </div>
                                            </div>
                                        </div>
                                        <base-button type="default" tag="a" href="mailto:agrzybowska@duolegal.pl" size="sm" class="float-right">Skontaktuj się</base-button>
                                    </div>
                                </div>
                            </div>
                        </tab-pane> -->
                    </card>
                </tabs>
            </div>
        </section>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                         <div class="image-stack">
                            <div class="image-stack__item image-stack__item--top">
                                <img src="img/theme/team3.jpg" alt="A portrait of a girl under hanging flowers." class="img-fluid rounded shadow-lg floating">
                            </div>
                            <div class="image-stack__item image-stack__item--bottom" >
                                <img src="img/theme/team1.jpg" alt="" class="img-fluid rounded shadow-lg floating">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <!-- <icon name="fa fa-users" class="mb-5" size="lg" type="duo" shadow
                                  rounded></icon> -->
                            <!-- <h3>Nasz cel</h3> -->
                            <p>Naszym głównym celem jest dbałość o interesy klienta, a w konsekwencji jego satysfakcja. 
                                Nasze usługi dostosowane są do charakterystyki sprawy i indywidualnych potrzeb klientów, z uwzględnieniem branży, w której działają.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
export default {
  components: {
    Tabs,
    TabPane
  }
};
</script>
<style>

.image-stack {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
}

.image-stack__item--bottom {
  grid-column: 4 / -1;
  grid-row: 1;
}

.image-stack__item--top {
  grid-row: 1;
  grid-column: 1 / span 8;
  padding-top: 40%;
  z-index: 1;
}

img {
  width: 100%;
  display: block;
}
</style>
